<template>
  <div>
    <Topbar />
    <Navbar />
    <Banner :hospital="hospital" />
    <Body :hospital="hospital" />
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Topbar from "@/components/home/Topbar.vue";
import Navbar from "@/components/home/Navbar.vue";
import Banner from "@/components/hospitalPage/Banner.vue";
import Body from "@/components/hospitalPage/Body.vue";
import Footer from "@/components/home/Footer.vue";
import { SocialChat } from "vue-social-chat";

export default {
  components: {
    Topbar,
    Navbar,
    Banner,
    Body,
    Footer,
    SocialChat,
  },
  data() {
    return {
      hospital: {},
    };
  },
  watch: {
    "$route.params.id"(newId, oldId) {
      if (newId !== oldId) {
        this.fetchHospital();
      }
    },
  },
  methods: {
    ...mapActions({
      getHospital: "appData/getHospitalPublic",
    }),
    async fetchHospital() {
      try {
        const res_hospital = await this.getHospital({
          pk: this.$route.params.id,
        });
        this.hospital = res_hospital.data;
      } catch (error) {
        console.error("Error fetching hospital:", error);
      }
    },
  },
};
</script>
